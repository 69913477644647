import { Component } from "react";
import OrganisationCard from "../components/OrganisationCards";
import { db } from "../js/Firebase";

class Ambassadors extends Component {
  state = {
    members: null,
    loadingSet1: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    db.collection("members")
      .orderBy("entry_id", "asc")
      .get()
      .then((snapshot) => {
        this.setState({ loadingSet1: true });
        console.log(this.loadingSet1);
        const members = [];
        snapshot.forEach((doc) => {
          let currentID = doc.id;
          let data = { ...doc.data(), id: currentID };
          members.push(data);
        });
        this.setState({ members: members });

        this.setState({ loadingSet1: false });
      })
      .catch((error) => console.log(error));
  }

  render() {
    return (
      <div className="container organisatie mb-5 pb-5" id="bestuur">
        <h1>Organisatie</h1>
        <div className="container bestuur">
          <div id="title"></div>

          {this.state.loadingSet1 && <div className="lds-circle"><div></div></div>}
          {!this.state.loadingSet1 && (
            <div id="bestuursleden" className="row">
              {this.state.members &&
                this.state.members.map((member) => {
                  return (
                    <OrganisationCard
                      img_url={member.img_url}
                      //img_url={IMG("placeholder-square-green.png")}
                      first_name={member.first_name}
                      last_name={member.last_name}
                      function={member.function}
                      e_mail={member.e_mail}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Ambassadors;
