import { Component } from "react";
import OrganisationCardAmbassadors from "../components/OrganisationCardAmbassadors";
import OrganisationCard from "../components/OrganisationCards";
import { db } from "../js/Firebase";
import ReactPaginate from "react-paginate";

const left = (
  <svg
    width="20"
    height="25"
    viewBox="0 0 20 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3761 2L3.20976 11.6742C2.62835 12.0712 2.62835 12.9288 3.20976 13.3258L17.3761 23"
      stroke="#747156"
      stroke-opacity="0.33"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const right = (
  <svg
    width="19"
    height="25"
    viewBox="0 0 19 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.9999 2L16.1662 11.6742C16.7476 12.0712 16.7476 12.9288 16.1662 13.3258L1.9999 23"
      stroke="#747156"
      stroke-opacity="0.33"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
class Organisatie extends Component {
  state = {
    members: null,
    ambassadors: [],
    loadingSet1: false,
    loadingSet2: false,
    currentPage: 0,
    postsPerPage: 3,
    posts: [],
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    

    db.collection("ambassadors")
      .orderBy("entry_id", "asc")
      .get()
      .then((snapshot) => {
        this.setState({ loadingSet2: true });
        const ambassadors = [];
        snapshot.forEach((doc) => {
          let currentID = doc.id;
          let data = { ...doc.data(), id: currentID };
          ambassadors.push(data);
        });
        this.setState({ ambassadors: ambassadors });

        this.setState({ posts: ambassadors });

        this.setState({ loadingSet2: false });
      })
      .catch((error) => console.log(error));
    this.setState({ loadingSet1: false });
    console.log(this.loadingSet1);
  }

  render() {
    // current page
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexFirstPost = indexOfLastPost - this.state.postsPerPage;
    //const currentPosts = this.state.posts.slice(indexFirstPost, indexOfLastPost);
    function resetSliders() {
      const sliders = [];
      const desc_su = document.getElementsByClassName("org-desc_su");
      const desc = document.getElementsByClassName("org-desc");
      const slider = document.getElementsByClassName("checkbox-slider");

      Array.prototype.forEach.call(slider, function (e) {
        sliders.push(e);
      });

      sliders.forEach((slider) => {
        console.log(slider.checked);
        if ((slider.checked = true)) {
          console.log(slider.checked, "indivslider");
          slider.checked = false;
          for (let index = 0; index < desc.length; index++) {
            const instance = desc[index];
            instance.style.display = "block";
          }
          for (let index = 0; index < desc_su.length; index++) {
            const instance = desc_su[index];
            instance.style.display = "none";
          }
        }
      });
    }
    const displayAmbassadors = this.state.ambassadors
      .slice(indexOfLastPost, indexOfLastPost + this.state.postsPerPage)
      .map((ambassador) => {
        return (
          <OrganisationCardAmbassadors
            id={ambassador.id}
            img_credit={ambassador.img_credit}
            img_url={ambassador.img_url}
            first_name={ambassador.first_name}
            last_name={ambassador.last_name}
            function={ambassador.function}
            member_desc={ambassador.description}
            member_desc_su={ambassador.description_su}
            e_mail={ambassador.e_mail}
            job_title={ambassador.job_title}
          />
        );
      });

    //page change
    const pageCount = Math.ceil(
      this.state.ambassadors.length / this.state.postsPerPage
    );
    const changePage = ({ selected }) => {
      this.setState({ currentPage: selected });
      resetSliders();
      var amb_container = document.getElementById("ambassadeurs-section");
      var pos =
        amb_container.getBoundingClientRect().top + window.pageYOffset - 40;
      window.scrollTo({ top: pos, behavior: "smooth" });
    };

    return (
      <div className="container organisatie">
       
        <div className="container ambassadeur" id="ambassadeurs-section">
          <h2>Ambassadeurs</h2>
          <div>{displayAmbassadors}</div>
          <ReactPaginate
            previousLabel={left}
            nextLabel={right}
            pageCount={pageCount}
            onPageChange={changePage}
            marginPagesDisplayed={2}
            containerClassName={"org-pagination pagination"}
            previousClassName={"org-pagination_previous"}
            nextClassName={"org-pagination_next"}
            disabledClassName={"org-pagination_disabled"}
            activeClassName={"org-pagination_active"}
            pageClassName={"org-pagination_li"}
            pageLinkClassName={"org-pagination_a"}
          />
        </div>
      </div>
    );
  }
}

export default Organisatie;
