import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB8XwwSH6257AR-o6HFDOE7SRrhAisyI3s",
    authDomain: "kenk-i.firebaseapp.com",
    databaseURL: "https://kenk-i-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "kenk-i",
    storageBucket: "kenk-i.appspot.com",
    messagingSenderId: "760057291350",
    appId: "1:760057291350:web:fb76ef792f6b37173451f7",
    measurementId: "G-KBG1C6H3KQ"
  };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth()
export const db = firebase.firestore()
export default firebase;