import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import ToTop from "../src/js/Totop";
import "../src/stylesheet.css";
import Home from "./pages/Home.js";
import Organisatie from "./pages/Organisatie.js";
import Ambassadors from "./pages/Ambassadors";
import Contact from "./pages/Contact.js";
import Navigation from "./components/Navbar copy";
import Footer from "./components/Footer";
import History from "./pages/Geschiedenis";
import Disclaimer from "./pages/Disclaimer";
import NotFoundFOF from "./pages/NotFound";
import Links from "./pages/Links.js";

const App = () => {
  return (
    <Router>
      <Switch>
        <div className="App">
          <Route 
          path={[
            "/organisatie",
              "/ambassadeurs",
              "/contact",
              "/geschiedenis",
              "/404-not-found",
              "/disclaimer",
              "/",
          ]}
          exact
          render={(props) => <Navigation {...props} className="navbar sticky-top navbar-light absolute" />}/>
          <ToTop>
            <Switch>
              <Route path="/" exact={true} component={Home} />
              <Route path="/organisatie" exact={true} component={Ambassadors} />
              <Route path="/contact" exact={true} component={Contact} />
              <Route path="/geschiedenis" exact={true} component={History} />
              <Route path="/ambassadeurs" exact={true} component={Organisatie} />
              <Route path="/disclaimer" exact={true} component={Disclaimer} />
              <Route path="/links" exact={true} component={Links} />
              <Route
                path="/404-not-found"
                exact={true}
                component={NotFoundFOF}
              />
              <Redirect from={"*"} to="/404-not-found" />
            </Switch>
          </ToTop>
          <Route
            path={["/", ""]}
            exact
            render={(props) => <Footer {...props} color={"background-dark"} />}
          />
          <Route
            path={[
              "/organisatie",
              "/ambassadeurs",
              "/contact",
              "/geschiedenis",
              "/404-not-found",
              "/disclaimer",
            ]}
            exact
            render={(props) => <Footer {...props} color={"background-light"} />}
          />
        </div>
      </Switch>
    </Router>
  );
}

export default App;
