import { Component } from "react";
import IMG from "../js/ImagePathLoader";

class Disclaimer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="disclaimer" id="disclaimer">
        <div className="container pt-2">
          <h1>Disclaimer</h1>
          <p>
            Het op deze site gebruikt beeldmateriaal is eigendom van
            Kenk-i.Voorafgaande schriftelijke toestemming is nodig bij gebruik
            door derden.
          </p>
          <p>
            <a href="https://www.freepik.com/vectors/business">
              Business vector created by stories - www.freepik.com
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default Disclaimer;
