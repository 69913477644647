import { Component } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import Hidden from "@material-ui/core/Hidden";

const IMG = (imgName) => {
  return require(`../assets/${imgName}`).default;
};

// const animateToggle = () =>{
//     let icon = document.getElementById('nav-icon');
//     icon.classList.toggle('open');
// }

class Navigation extends Component {
  render() {
    return (
      <div className="sticky-top" id="navbar-holder">
        <Navbar
          bg="white"
          expand="lg"
          id="navbar"
          className="navbar"
          sticky="top"
        >
          <Navbar.Brand>
            <NavLink to="/" exact className="nav-link">
              <img className="navbar-logo" src={IMG("logo.svg")} alt="logo" />
            </NavLink>
          </Navbar.Brand>

          <Hidden smDown>
            <Nav className="mr-auto">
              <ul className="nav-links">
                <NavLink to="/" exact className="nav-link">
                  <li className="nav-list-item">
                    <div className="nav-text">Home</div>
                  </li>
                </NavLink>

                <NavLink to="/geschiedenis" exact className="nav-link">
                  <li className="nav-list-item">
                    <div className="nav-text">Geschiedenis</div>
                  </li>
                </NavLink>
                <a
                  href="https://kenki.nl/home"
                  target="blank"
                  className="nav-link"
                >
                  <li className="nav-list-item">
                    <div className="nav-text">Woordenboek</div>
                  </li>
                </a>
                <a
                  href="https://docs.kenki.nl/herkomst"
                  target="blank"
                  className="nav-link"
                >
                  <li className="nav-list-item">
                    <div className="nav-text">Herkomst</div>
                  </li>
                </a>

                <a
                  href="https://docs.kenki.nl/spelling"
                  target="blank"
                  className="nav-link"
                >
                  <li className="nav-list-item">
                    <div className="nav-text">Spelling</div>
                  </li>
                </a>

                <NavLink to="/organisatie" exact className="nav-link">
                  <li className="nav-list-item">
                    <div className="nav-text">Organisatie</div>
                  </li>
                </NavLink>

                <NavLink to="/ambassadeurs" exact className="nav-link">
                  <li className="nav-list-item">
                    <div className="nav-text">Ambassadeurs</div>
                  </li>
                </NavLink>

                <NavLink to="/contact" exact className="nav-link">
                  <li className="nav-list-item">
                    <div className="nav-text">Contact</div>
                  </li>
                </NavLink>
              </ul>
            </Nav>
          </Hidden>

          <Hidden mdUp>
            <div className="">
              <Dropdown>
                <Dropdown.Toggle>
                  <div id="nav-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <ul className="nav-links">
                    <Dropdown.Item>
                      <NavLink to="/" exact className="nav-link">
                        <li className="nav-list-item">
                          <div className="nav-text">Home</div>
                        </li>
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink to="/geschiedenis" exact className="nav-link">
                        <li className="nav-list-item">
                          <div className="nav-text">Geschiedenis</div>
                        </li>
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="https://kenki.nl/home"
                      target="blank"
                      className="nav-link"
                    >
                      <span>
                        <li className="nav-list-item">
                          <div className="nav-text">Woordenboek</div>
                        </li>
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="https://docs.kenki.nl/herkomst"
                      target="blank"
                      className="nav-link"
                    >
                      <span>
                        <li className="nav-list-item">
                          <div className="nav-text">Herkomst</div>
                        </li>
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="https://docs.kenki.nl/spelling"
                      target="blank"
                      className="nav-link"
                    >
                      <span>
                        <li className="nav-list-item">
                          <div className="nav-text">Spelling</div>
                        </li>
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink to="/organisatie" exact className="nav-link">
                        <li className="nav-list-item">
                          <div className="nav-text">Organisatie</div>
                        </li>
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink to="/ambassadeurs" exact className="nav-link">
                        <li className="nav-list-item">
                          <div className="nav-text">Ambassadeurs</div>
                        </li>
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <NavLink to="/contact" exact className="nav-link">
                        <li className="nav-list-item">
                          <div className="nav-text">Contact</div>
                        </li>
                      </NavLink>
                    </Dropdown.Item>
                    <Dropdown.Item></Dropdown.Item>
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Hidden>
        </Navbar>
      </div>
    );
  }
}

export default Navigation;
