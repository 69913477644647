import {Component} from 'react';
import IMG from '../js/ImagePathLoader';
function translateText(id) {
    var switchId = id;
    var nl = document.getElementById(switchId + "-nl");
    var su = document.getElementById(switchId + "-su");
    var check = document.getElementById("checkbox-slider" + switchId);
    console.log(check, "esfeef")
    if (nl.style.display === "none" || su.style.display === "block" && check.checked === true) {
        nl.style.display = "block";
        su.style.display = "none";
        check.checked = false;
    } else {
        nl.style.display = "none";
        su.style.display = "block";
        check.checked = true;
    }
    console.log(check.checked)
    return;
}

class OrganisationCardAmbassadors extends Component {
    render() {
        return (
            <div className="org-info" id={this.props.id}>
                <div className="org-info-inner">
                    <div className="org-img col-lg-auto">
                        <picture>
                            {this.props.img_url === ""
                                ? (<img alt="placeholder" src={IMG("placeholder-square-green.png")}/>)

                                : (<img alt="org-member" src={this.props.img_url}/>)
}

                        </picture>
                        <p className="org-img-credit text-center">{this.props.img_credit}</p>
                    </div>
                    <div>
                        <div className="top-card show-mobile">
                            {this.props.member_desc === "" || this.props.member_desc_su === ""
                                ? 
                                null
                                :
                                <div className="language-slider mob" id="slider">
                                    <p className="slider-language-left">NL</p>
                                    <label className="switch" id={this.props.id}>

                                        <input
                                            id={"checkbox-slider" + this.props.id}
                                            className="checkbox-slider"
                                            type="checkbox"
                                            onClick={() => translateText(this.props.id)}/>

                                        <span className="slider"></span>
                                    </label>
                                    <p className="slider-language-right">SU</p>
                                </div>
                            }
                                        <div>
                                            <h3 className="org-name">{this.props.first_name} {this.props.last_name}</h3>
                                            {/* <p>{this.props.job_title}</p> */}
                                        </div>
                                        {this.props.member_desc === "" || this.props.member_desc_su === ""
                                        ? 
                                        null
                                        :
                                        <div className="language-slider full" id="slider">
                                            <p className="slider-language-left">NL</p>
                                            <label className="switch" id={this.props.id}>

                                                <input
                                                    id={"checkbox-slider" + this.props.id}
                                                    className="checkbox-slider"
                                                    type="checkbox"
                                                    onClick={() => translateText(this.props.id)}/>

                                                <span className="slider"></span>
                                            </label>
                                            <p className="slider-language-right">SU</p>
                                        </div>
                                        }
                                    </div>

                        

                        <div>
                            <p className="org-func">{this.props.function}</p>
                            {
                                this.props.member_desc === ""
                                ?
                                <p className="org-desc_su" id={this.props.id + "-su"}>{this.props.member_desc_su}</p>
                                :
                                <p className="org-desc" id={this.props.id + "-nl"}>{this.props.member_desc}</p>
                            }

                            {/* <p className="org-desc" id={this.props.id + "-nl"}>{this.props.member_desc}</p> */}
                            <p className="org-desc_su" id={this.props.id + "-su"}>{this.props.member_desc_su}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default OrganisationCardAmbassadors;