import { Component } from "react";
import { NavLink } from "react-router-dom";
import IMG from "../js/ImagePathLoader";

class Home extends Component {
  componentDidMount() {
    document
      .getElementById("carrousel-images")
      .addEventListener("contextmenu", (e) => {
        e.preventDefault();
      });
    window.scrollTo(0, 0);
  }
  /* <!-- START RENDER --> */
  render() {
    return (
      <div id="home">
        {/* <!-- START HERO --> */}
        <section id="hero">
          <div className="hero-content">
            <div className="container">
              <h1>KENK-I</h1>
              <p>
                Stichting KENK-I zet zich in voor de Surinaamse taal, het
                Sranantongo. Met de inzet van KENK-I moeten meer mensen het
                Sranantongo kunnen lezen en op een juiste wijze kunnen
                schrijven. Voor dit doel werkt KENK-I samen met personen,
                instanties en instellingen in Suriname en daar buiten.{" "}
              </p>
            </div>
          </div>
          <picture oncontextmenu="return false;">
            <source media="(max-width:500px)" srcset={IMG("mobile/hero.svg")} />
            <source media="(max-width:772px)" srcset={IMG("tab/hero.svg")} />
            <img src={IMG("hero.svg")} className="w-100" alt="" id="hero" />
          </picture>
        </section>
        {/* <!-- END HERO --> */}

        {/* <!-- START Sranantongo --> */}
        <section id="sranantongo" className="section-content">
          <div className="container d-block d-md-flex">
            <div className="col-md-6 col-sm-12 text-middle">
              <h2>Geschiedenis van het Sranantongo</h2>
              <p>
                Het Sranantongo is een bijna vier-eeuwenoude taal die ontstaan
                is als contacttaal tussen de totslaafgemaakten die afkomstig uit
                verschillende delen van Afrika onderling en de Europese
                overheersers. De Surinaamse bevolking bestaat uit een unieke mix
                van verschillende culturen onderling.
              </p>

              <NavLink exact to="/geschiedenis">
                <a className="btn btn-primary mb-5 mb-md-0">leer meer</a>
              </NavLink>
            </div>

            <div className="col-md-5 offset-md-1 col-sm-12">
              <picture>
                <source />
                <img className="w-100" src={IMG("sranantongo.png")} alt="" />
              </picture>
            </div>
          </div>
        </section>
        {/* <!-- END Sranantongo --> */}

        {/* <!-- START MISSIE --> */}
        <section id="missie" className="section-dark section-content">
          <div className="container d-block d-md-flex">
            <div className="col-md-5 col-sm-12">
              <picture>
                <source />
                <img className="w-100" src={IMG("missie.png")} alt="" />
              </picture>
            </div>

            <div className="col-md-6 offset-md-1 col-sm-12 text-middle">
              <h2>Missie</h2>
              <p>
                KENK-I houdt het Sranantongo levend. Wij stimuleren de
                taalontwikkeling van Sranantongo met workshops, symposia en
                cursussen, betrekken deskundige personen en instituten en
                adviseren op vraagstukken die betrekking hebben op het verder
                standaardiseren van de taal.
              </p>
            </div>
          </div>
        </section>
        {/* <!-- END MISSIE --> */}

        {/* <!-- START VISIE --> */}
        <section id="visie" className="section-content">
          <div className="container d-block d-md-flex">
            <div className="col-md-6 col-sm-12 text-middle">
              <h2>Visie</h2>
              <p>
                KENK-I streeft naar een Surinaamse samenleving waarin het
                Sranantongo door iedereen wordt begrepen en gesproken, maar
                bovenal wordt geschreven met de juiste spelling en grammatica.
              </p>
            </div>

            <div className="col-md-5 offset-md-1 col-sm-12">
              <picture>
                <source />
                <img className="w-100" src={IMG("visie.png")} alt="" />
              </picture>
            </div>
          </div>
        </section>
        {/* <!-- END VISIE --> */}

        {/* <!-- START CAROUSEL --> */}
        <section id="carousel" className="section-dark-50 section-content">
          <div
            id="img-slide"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div
              className="carousel-inner"
              onContextMenu="return false;"
              id="carrousel-images"
            >
              {/* <!-- CAROUSEL ITEM 1 --> */}
              <div className="carousel-item active">
                <picture>
                  <source
                    media="(max-width:430px)"
                    srcset={"https://firebasestorage.googleapis.com/v0/b/kenk-i.appspot.com/o/assets%2Fimg%2Fcarousel%2FRectangle%2047carousel.png?alt=media&token=fcbfb1cc-b0e6-4cba-a26a-8db22d7b9e12"}
                  />
                  <img
                    src={"https://firebasestorage.googleapis.com/v0/b/kenk-i.appspot.com/o/assets%2Fimg%2Fcarousel%2FRectangle%2047carousel.png?alt=media&token=fcbfb1cc-b0e6-4cba-a26a-8db22d7b9e12"}
                    className="w-100"
                    alt="Powema boto in Gemaal op Zuid."
                    onContextMenu="return false;"
                  />
                </picture>
              </div>

              {/* <!-- CAROUSEL ITEM 2 --> */}
              <div className="carousel-item">
                <picture>
                  <source
                    media="(max-width:430px)"
                    srcset={"https://firebasestorage.googleapis.com/v0/b/kenk-i.appspot.com/o/assets%2Fimg%2Fcarousel%2FRectangle%2048carousel.png?alt=media&token=ec0946ce-3b77-4b7b-8afa-ec786424b26c"}
                  />
                  <img
                    src={"https://firebasestorage.googleapis.com/v0/b/kenk-i.appspot.com/o/assets%2Fimg%2Fcarousel%2FRectangle%2048carousel.png?alt=media&token=ec0946ce-3b77-4b7b-8afa-ec786424b26c"}
                    className="w-100"
                    alt="Powema boto in Gemaal op Zuid."
                    onContextMenu="return false;"
                  />
                </picture>
              </div>

              {/* <!-- CAROUSEL ITEM 3 --> */}
              <div className="carousel-item">
                <picture>
                  <source
                    media="(max-width:430px)"
                    srcset={"https://firebasestorage.googleapis.com/v0/b/kenk-i.appspot.com/o/assets%2Fimg%2Fcarousel%2FRectangle%2046carousel.png?alt=media&token=24611773-157f-4efb-ba4c-7547955b2929"}
                  />
                  <img
                    src={"https://firebasestorage.googleapis.com/v0/b/kenk-i.appspot.com/o/assets%2Fimg%2Fcarousel%2FRectangle%2046carousel.png?alt=media&token=24611773-157f-4efb-ba4c-7547955b2929"}
                    className="w-100"
                    alt="Powema boto in Gemaal op Zuid."
                    onContextMenu="return false;"
                  />
                </picture>
              </div>

            </div>

            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#img-slide"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#img-slide"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </section>
        {/* <!-- END CAROUSEL --> */}

        {/* <!-- START DOELEN --> */}
        <section id="doelen" className="section-dark section-content pt-0">
          <div className="container d-block">
            <div className="col-12 text-center pb-4">
              <h2>Doelen</h2>
            </div>

            <div className="d-md-flex justify-content-around mx-auto text-center">
              <div className="col-12 col-md-4">
                <picture className="d-flex justify-content-center goal-img">
                  <source />
                  <img src={IMG("develop.png")} alt="" />
                </picture>

                <p>Bijdragen aan de ontwikkeling van het Sranantongo</p>
              </div>

              <div className="col-12 col-md-4">
                <picture className="d-flex justify-content-center goal-img">
                  <source />
                  <img src={IMG("spread.png")} alt="" />
                </picture>
                <p>Het Sranantongo verder uitdragen</p>
              </div>

              <div className="col-12 col-md-4">
                <picture className="d-flex justify-content-center goal-img">
                  <source />
                  <img src={IMG("strenght.png")} alt="" />
                </picture>

                <p>Het versterken van het Sranantongo</p>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- END DOELEN --> */}

        {/* <!-- START PARTNERS --> */}
        <section id="partners" className="section-content hidden">
          <div className="container d-block d-md-flex">
            <h2>Partners</h2>
          </div>
        </section>
        {/* <!-- END PARTNERS --> */}
      </div>
    );
  }
}

export default Home;
