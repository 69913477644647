const Loader = (source, el, alt) => {
  var loadedImage = new Image();
  var desc = alt;
  loadedImage.src = source;
  loadedImage.attributes.alt = desc;
  loadedImage.onload = function () {
    el.replaceWith(loadedImage);
  };
};

export default Loader;
