import { Component } from "react";
import IMG from "../js/ImagePathLoader";

class History extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="container geschiedenis pb-5">
        <h2>Geschiedenis van het Sranantongo</h2>
        <div className="row">
          <div className="col-12 col-md-7">
            <p>
              Het Sranantongo is een bijna vier eeuwen oude taal, die ontstaan
              is als contacttaal tussen de uit verschillende delen van Afrika
              afkomstige totslaafgemaakten en de Europese overheersers.
            </p>
            <p>
              Het was de totslaafgemaakten niet toegestaan Nederlands te leren.
              De voertaal was het Sranan of het Negerengels, zoals de taal toen
              genoemd werd.
            </p>

            <p>
              Inspecteur van Onderwijs H. Benjamins voerde in 1891 het
              Nederlands als schooltaal in, omdat hij vond dat het Neger-Engels
              -waar tot dan het onderwijs in werd gegeven- het goed aanleren van
              het Nederlands in de weg stond. Uit die tijd stammen de zo bekende
              strafregels: <i>“Ik mag geen Negerengels spreken op school”</i>.
              Ook buiten de scholen werd de taal geweerd.
            </p>
            <p>
              Dit heeft gelukkig het Sranantongo niet doen verdwijnen, maar is
              het tot de dag van vandaag de lingua franca, de gemeenschappelijke
              taal, gebruikt door de verschillende groeperingen in multi-etnisch
              Suriname.
            </p>

            <p>
              Papa Koenders, bekend van het Sranantongo maandblad Foetoe-boi dat
              tussen 1946 en 1956 werd uitgegeven, heeft de strijd voor het
              behoud van de taal gevoerd, maar ook organisaties als Wie Eegie
              Sanie. Naast Papa Koenders hebben schrijvers en dichters als
              Trefossa, Eddie Bruma, Michaël Slory, Johanna Schouten-Elsenhout,
              Dobru en anderen bewezen dat de taal een volwaardige taal is.
            </p>
            <p>
              Het Sranantongo wint nu weer aan kracht onder Surinamers in en
              buiten Suriname. Niet in het minst vanwege de toename van het
              gebruik van Sranantongo op sociale media en andere fora. Ook
              spreken meer jongeren de taal. Door deze ontwikkelingen
              verschijnen er ook meer boeken in het Sranantongo.
            </p>
            <p>
              In 1960 en daarna in 1986 is de spelling van het Sranantongo bij
              staatsbesluit in een resolutie vastgelegd.
            </p>
          </div>
          <div className="col-12 offset-md-1 col-md-4 offset-0">
            <img
              class="w-100"
              src={IMG("papa koenders.png")}
              alt="papa koenders"
            />
            <p className="text-center text-small">Papa Koenders</p>
          </div>
        </div>
      </div>
    );
  }
}
export default History;
