import {Component} from 'react';
import IMG from '../js/ImagePathLoader';
  
class OrganisationCard extends Component{
    render(){
        return(
            <div className="org-info col-12 col-md-4" id={this.props.id}>
                <div className="m-auto org-info-inner text-center">
                    <div className="org-img">
                        <picture>
                            {
                                this.props.img_url === "" ?
                                    (
                                        <img alt="placeholder" src={IMG("placeholder-square-green.png")}/>
                                    )
                                    
                                :
                                    (
                                        <img alt="org-member" src={this.props.img_url}/>
                                    )
                                    
                                
                            }
                            
                        </picture>
                        <p className="org-img-credit text-center">{this.props.img_credit}</p>
                    </div>
                    <h3 className="org-name">{this.props.first_name} {this.props.last_name}</h3>
                    <p className="org-func">{this.props.function}</p>
                    <p className="org-desc" id={this.props.id + "-nl"} >{this.props.member_desc}</p>
                    <div>
                        <a className="org-email" href={"mailto:"+this.props.e_mail}>{this.props.e_mail}</a>
                    </div>
                </div>
            </div>
        )
    }
}
export default OrganisationCard;