import { Component } from "react";
import IMG from "../js/ImagePathLoader";
import { NavLink } from "react-router-dom";

class NotFoundFOF extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="not-found" id="not-found">
        <div className="container col-md-10 col-xl-6 col-xxl-6">
          <img className="w-100" src={IMG("404.svg")} alt="not found" />
          <div>
            <h1>Oeps!</h1>
            <p>Pagina niet gevonden</p>
            <div className="nf-link">
              <NavLink to="/" exact>
                Terug naar startpagina
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundFOF;
