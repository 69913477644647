import { useEffect } from "react";
import Loader from "../components/Loader";

const Links = () => {
  useEffect(() => {
    Loader(
      "https://firebasestorage.googleapis.com/v0/b/kenk-i.appspot.com/o/assets%2Fimg%2FLogo.png?alt=media&token=c5a0ad2e-b4c6-4fd7-956f-bd1d3e83b098",
      document.getElementById("594"),
      "logo"
    );
  });
  window.scrollTo(0, 0);

  return (
    <div className="links" id="links">
      <div className="inner"></div>
      <div className="tree"></div>
      <div className="contain">
        <div className="top-info">
          <div className="picture w-10">
            <div id="594" className="loader"></div>
          </div>
          <div className="info">
            <p>Skrifi nanga leysi Sranantongo bun</p>
          </div>
        </div>
        <div className="links-list">
          <ul className=" btn-group btn-group-vertical">
            <li className="link">
              <a
                className="btn btn-primary"
                href="https://kenk-i.org/"
                target="void[]"
              >
                <i className="fas fa-accessible-icon icon w-100"></i>
                Website
              </a>
            </li>
            <li className="link">
              <a
                className="btn btn-primary"
                href="https://www.youtube.com/@Stichting-KENK-I"
                target="void[]"
              >
                <i class="fas fa-accessible-icon    "></i>
                Youtube
              </a>
            </li>
            <li className="link">
              <a
                className="btn btn-primary"
                href="https://www.facebook.com/share/7UPes66vQcAR33Gx/?mibextid=LQQJ4d"
                target="void[]"
              >
                <i class="fas fa-accessible-icon    "></i>
                Facebook
              </a>
            </li>
            <li className="link">
              <a
                className="btn btn-primary"
                href="https://www.instagram.com/kenki_org"
                target="void[]"
              >
                <i class="fas fa-accessible-icon    "></i>
                Instagram
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Links;
