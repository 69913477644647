import { Component } from "react";
import IMG from "../js/ImagePathLoader";

class Contact extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="contact" id="contact">
        <div className="container">
          <h1>Neem contact op</h1>
          <p>
            Heeft u een vraag of opmerking? mail ons. <br />
            Wij zullen zo spoedig mogelijk contact met u opnemen.
          </p>
          <p>
            <a href="mailto:info@kenk-i.org">info@kenk-i.org</a>
          </p>
          <div className="col-md-6 col-sm-12 pt-4">
            <picture>
              <source />
              <img
                className="w-100"
                src={IMG("contact.png")}
                alt="get in touch"
              />
            </picture>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
